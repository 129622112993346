<template>
  <div
    v-if="rowMap.length"
    :id="`multi-line-row-${index}`"
    :class="['multi-line', !disableBottomSpacing ? 'hasBottomSpace' : '']"
    :style="cardBg"
    @click="handleRowClick(`multi-line-row-${index}`)"
  >
    <template v-for="(colItem, colIndex) in rowMap">
      <component
        :is="compMap(colItem)"
        :ref="`${colItem?.props?.metaData?.templateType}-${index}-${colIndex}`"
        :key="`${colIndex}`"
        :index="index"
        :container-index="colIndex"
        :context="context"
        :scene-data="sceneData"
        :cate-links="cateLinks"
        :prop-data="colItem"
        :card-info="cardInfo"
        :language="language"
        :brand="brand"
        :is-first-page="isFirstPage"
        :home-discount-new="homeDiscountNew"
        :show-strengthen-price="showStrengthenPrice"
        :home-adp-num="homeAdpNum"
        :use-front-cate-id="useFrontCateId"
        :is-early-request0903="isEarlyRequest0903"
        :css-right="cssRight"
        :product-item-config="productItemCommonConfig"
        :class="compClass(colItem)"
        class="multi-line__col"
        :style="compCardStyle(colItem)"
        :crop-rate="isHomeR56 ? '5-6' : '3-4'"
        :suggested-sale-type="compSuggestedSaleType(colItem)"
      >
        <div
          slot="multiLineBg"
          class="multi-line__col_bg"
          :style="[compCardStyle(colItem), compBgStyle(colItem)]"
        ></div>
      </component>
    </template>
  </div>
</template>

<script>
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import useProductItemMixin from 'public/src/pages/components/ccc/components/mixins/use-product-item.js'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { mapGetters } from 'vuex'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import SingleContainer from './container/SingleContainer'
import HalfContainer from './container/HalfContainer'
import QuarterContainer from './container/QuarterContainer'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'
import { transfromAndCutImg } from 'public/src/services/resource'
import { CompSize } from './constants.js'

const ComponentSizeType = {
  1: { dep: '356.09deg', startOpacity: '85%' },
  0.5: { dep: '349deg', startOpacity: '80%' },
  0.25: { dep: '338deg', startOpacity: '80%' }
}

export default {
  name: 'MultiLineBff',
  components: {
    BaseImg,
    SingleContainer,
    HalfContainer,
    QuarterContainer
  },
  mixins: [mixin, useProductItemMixin],
  props: commonProps,
  data() {
    const { GB_cssRight, SiteUID } = this.context || {}
    const {
      props = {},
      content = [],
      isFirstPage = true,
    } = this.propData || {}
    const {
      HomeDiscount,
      HomeAdpNum,
      BGPrice,
      FSCategory,
      SuperDealsPerformanceOptimization,
      HomePictureProcess
    } = this.context?.abtInfoObj || {}
    const { metaData = {} } = props || {}
    const {
      disableBottomSpacing = true,
      cardRadius = 5,
      margin = [],
      isCardShow = false
    } = metaData || {}
    return {
      GB_cssRight,
      // props
      isFirstPage,
      containerContent: content, 
      disableBottomSpacing,
      homeDiscountNew: HomeDiscount,
      homeAdpNum: HomeAdpNum,
      showStrengthenPrice: BGPrice === 'newprice13',// 不贵组件加强价格
      useFrontCateId: FSCategory  === 'B',
      isEarlyRequest0903: SuperDealsPerformanceOptimization === 'early_tmg_request_0903', // 落地页早期tmg请求标志
      isHomeR56: HomePictureProcess === 'Home_picture',
      // status
      cardRadius,
      margin,
      isCardShow,
      siteUid: SiteUID,
    }
  },
  provide() {
    return {
      isProductPage: true // 向商卡传递该变量 否则商卡前6张图片会触发懒加载
    }
  },
  computed: {
    ...mapGetters('config_index', ['channelId']),
    // 商品项公共配置
    productItemCommonConfig() {
      return {
        showTitle: false,
        useOwnClickExposeAnalysis: true,
        showCamelPrice: true, // 驼峰式价格
        disableMainimgJump: true,
        showNewDiscountBlet: false, // 是否展示新的闪购腰带
        hideRetailPrice: true, // 隐藏零售价（划线价）- 全端去除划线价
        hideS3VipPrice: true, // 隐藏s3会员价格
        showSheinClubPriceOnSale: true, // 会员价固定配置显示，是否显示由数据决定
        showEstimatedPriceOnSale: true, // 到手价固定配置显示，是否显示由数据决定
        noNeedAnyEstimatedPriceTag: true, // 不需要任何到手价标签
        hideIconBeforePrice: this.homeDiscountNew === 'noshow', // 隐藏价格前面的icon
        hideServiceLabels: this.homeDiscountNew === 'noshow', // 服务标签
        camelPriceResizeFontsize: true, // 驼峰价格动态字号
        showSuggestedSalePrice: this.homeDiscountNew === 'compliance', // 是否展示合规价
        style: {
          pricesNewline: true,
          salePriceFontSize: '.32rem',
          lineThroughPriceFontSize: '.32rem',
          lineThroughPriceScale: 0.833,
        }
      }
    },
    rowMap() {
      // 按每行需要显示的组件来处理
      this.containerContent.forEach(comp => {
        let { productsV2 = [], flashProducts, flashProductsV2 = [], storeInfoList, templateType = '' } = comp?.props?.metaData ?? {}
        
        // 如果是新的商品数据结构，将其转换为老的数据结构
        if (productsV2?.length) {
          comp.props.metaData.products = productsV2
          delete comp.props.metaData.productsV2
        }
        // 如果是新的商品数据结构，将其转换为老的数据结构
        if (flashProductsV2?.length) {
          comp.props.metaData.flashProducts = flashProductsV2
          flashProducts = flashProductsV2
          delete comp.props.metaData.flashProductsV2
        }
        const products = comp.props.metaData.products

        if (this.cssRight && products?.length) {
          // 镜像时，非店铺、趋势组件
          products.forEach(productItem => {
            if (templateType === 'trend' && productItem.homeBadge?.text) {
              productItem.homeBadge.textRotate = true
            }
          })
        }
        if (templateType === 'superDeals') {
          if (flashProducts?.length) {
            flashProducts.forEach(flashItem => {
              flashItem.type = 'flashSale'
              if (flashItem.homeBelt?.text) {
                flashItem.homeBelt.textHeight = '70%'
                flashItem.homeBelt.textWeight = '700'
              }
            })
          }
          products.forEach(productItem => {
            productItem.type = 'superSale'
          })
        }
        if (templateType === 'shop') {
          if (storeInfoList?.length) {
            comp.props.metaData.products = storeInfoList
            delete comp.props.metaData.storeInfoList
          }
        }
      })
      return this.containerContent
    },
    // 全局变量有说明时专题页面 否则为首页
    cardInfo() {
      const extendData =
        this.sceneData?.extendParse || this.sceneData?.extendData || {}
      const { cardRadius, margin, isCardShow } = extendData
      if (isCardShow) {
        return {
          cardRadius,
          margin,
          isCardShow
        }
      } else {
        return {
          cardRadius: this.cardRadius,
          margin: this.margin,
          isCardShow: this.isCardShow
        }
      }
    },
    cardBg() {
      const { margin, isCardShow } = this.cardInfo
      if (!isCardShow) return {}
      const top = margin?.[0] || 0
      const right = margin?.[1] || 0
      const bottom = margin?.[2] || 0
      const left = margin?.[3] || 0
      return {
        margin: `${top}px ${right}px ${bottom}px ${left}px`
      }
    },
    cssRight() {
      return this.GB_cssRight
    }
  },
  watch: {
    channelId() {
      this.handleShop(false)
    }
  },
  deactivated() {
    this.handleShop(false, true)
  },
  activated() {
    this.$nextTick(() => {
      this.handleShop(false)
    })
  },
  async mounted() {
    this.handleShop(true)
  },
  methods: {
    handleRowClick(id) {
      sessionStorage.setItem('multiLineRowId', id)
    },
    compMap(colItem) {
      switch (+colItem?.props?.metaData?.componentSize) {
        case CompSize.full:
          return 'SingleContainer'
        case CompSize.half:
          return 'HalfContainer'
        case CompSize.quarter:
          return 'QuarterContainer'
        default:
          break
      }
    },
    compClass(colItem) {
      if (this.homeDiscountNew === 'noshow') {
        return {
          'product-item__discount_color-hide': true,
        }
      }
      let { componentSize, isDeStyle, nextLine } = colItem?.props?.metaData || {}
      componentSize = Number(componentSize)
      if (this.siteUid !== 'pwfr' && componentSize !== 1) {
        // 非法国站点，非整行组件不展示合规价
        if (this.homeDiscountNew === 'compliance') {
          // 非法国站点，非整行，命中compliance等同于noshow
          return {
            'product-item__discount_color-hide': true,
          }
        } else {
          return {}
        }
      }
      nextLine = nextLine ? Number(nextLine) : 0
      isDeStyle = isDeStyle ? Number(isDeStyle) : 0
      return {
        'multi-line__col_suggested': this.homeDiscountNew === 'compliance', 
        'multi-line__col_suggested-sameline': this.homeDiscountNew === 'compliance' && !nextLine && !isDeStyle, // de合规价固定不受nextLine影响
        'multi-line__col_suggested-de': this.homeDiscountNew === 'compliance' && isDeStyle // de合规价样式覆盖
      }
    },
    compSuggestedSaleType(colItem) {
      if (this.homeDiscountNew !== 'compliance') {
        return ''
      }
      let { isDeStyle } = colItem?.props?.metaData || {}
      isDeStyle = isDeStyle ? Number(isDeStyle) : 0
      if (isDeStyle) {
        return 'special_de'
      } else if (this.siteUid === 'mpl') {
        return 'special'
      } else {
        return 'normal'
      }
    },
    cutImg(imgUrl, forceTransform = false) {
      const {
        deviceData = '',
        isSupportWeb = '',
        sceneMap = {},
        isSupprotCut = ''
      } = this.context?.RESOURCE_SDK || {}
      const cutData = {
        deviceData,
        isSupportWebp: forceTransform ? true : Boolean(isSupportWeb),
        isSupprotCut: forceTransform ? true : Boolean(isSupprotCut),
        imgUrl,
        designWidth: 950,
        sceneMap,
        exp: 'unCut=0#max=950'
      }
      return transfromAndCutImg(cutData)
    },
    compCardStyle(colItem) {
      const { bgFillType, bgImage } = colItem?.props?.metaData
      const { cardRadius = 2 } = this.cardInfo
      const style = {
        borderRadius: `${cardRadius / 37.5}rem`
      }
      if (bgFillType === 'image' && bgImage?.src) {
        return style
      }
      // 纯色渐变添加边框
      return {
        ...style,
        border: '0.5px solid rgba(0, 0, 0, 0.03)'
      }
    },
    compBgStyle(colItem) {
      const { bgFillType, bgImage, bgColor, bgColorTransparency } = colItem?.props?.metaData
      if (bgFillType === 'image' && bgImage?.src) {
        return {
          backgroundImage: `url(${this.cutImg(bgImage?.src)})`,
          backgroundSize: 'cover',
          backgroundPosition: `${this.cssRight ? 'left' : 'right'} center`,
          height: '99%'
        }
      }
      const opacityArr = bgColorTransparency?.split(',')
      const opacity1 = opacityArr[0] ? opacityArr[0] / 100 : '0.11'
      const opacity2 = opacityArr[1] ? opacityArr[1] / 100 : '0'
      const componentSize = colItem.props.metaData.componentSize || 1
      return {
        background: `linear-gradient(${ComponentSizeType[componentSize]?.dep || '356.09deg'}, ${hexToRGBA(bgColor, opacity2)} ${ComponentSizeType[componentSize]?.startOpacity || '85%'}, ${hexToRGBA(bgColor, opacity1)} 100%)`,
        height: '4rem' // 渐变高度
      }
    },
    // 店铺特殊处理方法
    handleShop(isMounted = false, isDeactivate = false) {
      if (!this.rowMap.length) return
      this.rowMap.forEach((col, colIndex) => {
        if (col?.props?.metaData?.templateType === 'shop') {
          const componentSize = col?.props?.metaData?.componentSize
          this.$nextTick(() => {
            let containerRefs = this.$refs[`shop-${this.index}-${colIndex}`][0]
            let shopList = containerRefs?.$refs[`shop-${componentSize}-${colIndex}`]
            if (shopList) {
              this.initShopSwiper(componentSize, shopList, isMounted, isDeactivate)
            } else {
              this.gethandleShop(componentSize, colIndex, isMounted, isDeactivate)
            }
          })
        }
      })
    },
    // 获取shop组件信息
    gethandleShop(componentSize, colIndex, isMounted, isDeactivate) {
      setTimeout(() => {
        let containerRefs = this.$refs[`shop-${this.index}-${colIndex}`][0]
        let shopList = containerRefs?.$refs[`shop-${componentSize}-${colIndex}`]
        if (shopList) {
          this.initShopSwiper(componentSize, shopList, isMounted, isDeactivate)
        } else {
          this.gethandleShop(componentSize, colIndex, isMounted, isDeactivate)
        }
      }, 500)
    },
    // 店铺卖点轮播
    initShopSwiper(componentSize, shopList, isMounted, isDeactivate) {
      if (componentSize == '0.25') {
        // 1/4组件
        shopList = [shopList]
      }
      if (shopList.length) {
        shopList.forEach(shopItem => {
          if (isMounted) {
            shopItem.initProductsSwiper()
          } else {
            if (!isDeactivate) {
              shopItem.autoPlay()
            } else {
              shopItem.stopPlay()
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.multi-line {
  margin-top: 16/75rem;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  margin-top: 16/75rem;
  justify-content: space-between;
  &__col {
    position: relative;
    z-index: 1;
    overflow: hidden;
    &_bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -2;
    }
    &_bg::after {
      content: '';
      position: absolute;
      top: 30%;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 72%;
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%, #FFFFFF 100%);
      z-index: -1;
    }
    // 合规价样式覆盖
    &_suggested {
      /deep/ .product-bff {
        .product-card__prices {
          max-height: unset;
        }
        .product-card__prices-info, 
        .prices-info {
          width: 100%;
        }
        .bff-price-container {
          align-items: baseline;
          position: relative;
          .bff-price-container__top {
            .product-card__camel-case-price {
              height: unset;
              line-height: normal;
            }
          }
          .bff-price-container__top, 
          .prices-info__sale-price {
            height: unset;
            line-height: normal;
          }
          .prices-info__suggested-sale-price {
            flex: unset;
          }
          .product-card__price-suggested {
            font-size: 0.24rem;
            margin-left: 2px;
          }
          .suggested-label {
            position: absolute;
            bottom: 2px;
            left: 0;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      /deep/ .product-bff.product-simple__suggested_label .bff-price-container {
        padding-bottom: 12px;
      }
    } 
    // 合规加展示价格同行
    &_suggested-sameline /deep/ .product-bff .bff-price-container {
      flex-direction: row;
      // flex-wrap: wrap;// 价格+合规价超长，合规价可换行
    }
    // de合规价样式覆盖
    &_suggested-de {
      /deep/ .product-bff {
        .bff-price-container {
          .bff-price-container__top {
            flex-wrap: nowrap;
            width: 100%;
            .prices-info__sale-price {
              max-width: calc(100% - 0.48rem); // 划线价如果展示不下时要末尾「…」，不能隐藏
            }
            .product-card__price-suggested { 
              display: block;
              margin-left: 2px;
            }
          }
          .product-card__price-suggested {
            display: flex;
            align-items: center;
            max-width: 100%;
            margin-left: 0;
            .suggested-label {
              position: unset;
            }
          }
        }
      }
      /deep/ .product-bff.product-simple__suggested_label .bff-price-container {
        padding-bottom: 0px;
      }
    }
  }
  // 不贵组件，不展示合规价，不加强价格，固定桔红色
  &__col:not(.product-item__discount_color-hide):not(.multi-line__col_suggested) /deep/ .multi-line__superDeals_item:not(.strengthen-price) .prices-info__sale-price {
    color: @sui_color_discount;
  }
  // 无合规样式类，隐藏合规价
  &__col:not(.multi-line__col_suggested) /deep/ .product-card__price-suggested {
    display: none;
  }
  &.hasBottomSpace {
    margin-bottom: 0.21rem;
  }
  .product-item__discount_color-hide {
    /deep/ .simple-row-item .prices-info__sale-price {
      color: @sui_color_main;
    }
  }
}
</style>
