// 组件模板类型
export const CompTemplateType = {
  superDeals: 'superDeals', // 不贵
  trend: 'trend', // 趋势
  brand: 'brand', // 品牌馆
  category: 'category', // 品类周
  newProduct: 'newProduct', // 新品
  shop: 'shop', // 精选店铺
  custom: 'custom', // 自定义
}

// 标题类型
export const TitleType = {
  text: 'text', // 文本
  image: 'image', // 图片
}

// 组件面积
export const CompSize = {
  full: 1, // 整行
  half: 0.5, // 1/2
  quarter: 0.25, // 1/4
}

