<template>
  <div class="multi-line__single_content">
    <slot name="multiLineBg"></slot>
    <MultiLineHeader
      :css-right="cssRight"
      :prop-data="headerPropData"
      :is-first-page="isFirstPage"
      :index="index"
      :container-index="containerIndex"
      :cate-links="cateLinks"
      :products-list="productsList"
      :home-adp-num="homeAdpNum"
      :use-front-cate-id="useFrontCateId"
      :is-early-request0903="isEarlyRequest0903"
      :scene-data="sceneData"
      :context="context"
    />
    <VirtualSwiper
      v-if="productsList.length > 0"
      ref="singleContainerSwiperRef"
      class="single-container__swiper"
      :options="options"
      :source-data="productsList"
      :initVirtualNum="Math.ceil(productsNum)"
    >
      <template #default="{ virtualData }">
        <swiper-slide
          v-for="(item, itemIndex) in virtualData"
          :key="`${item.goods_id}_${itemIndex}`"
          class="single-container__swiper_slide"
          :style="{
            width: `calc(100% / ${productsNum})`,
          }"
        >
          <component
            :is="currentComponent"
            :ref="`${propData?.props?.metaData?.templateType}-1-${containerIndex}`"
            :index="index"
            :product-item-config="productItemConfig"
            :css-right="cssRight"
            :context="context"
            :prop-data="propData"
            :product-item="item"
            :product-item-index="itemIndex"
            :container-index="containerIndex"
            :is-first-page="isFirstPage"
            :show-view-more="itemIndex === productsList.length - 1"
            :view-more-text="viewMoreText"
            :scene-data="sceneData"
            :products-list="productsList"
            :cate-links="cateLinks"
            :home-discount-new="homeDiscountNew"
            :show-strengthen-price="showStrengthenPrice"
            :home-adp-num="homeAdpNum"
            :use-front-cate-id="useFrontCateId"
            :products-num="productsNum"
            :is-early-request0903="isEarlyRequest0903"
            :crop-rate="cropRate"
            :suggested-sale-type="suggestedSaleType"
          />
        </swiper-slide>
      </template>
    </VirtualSwiper>
    <PlaceholderItem
      v-else
      :num="Math.floor(productsNum)"
      :brand="brand"
      :is-small-item="true"
      :horizontal-item-width="85.38"
    />
  </div>
</template>

<script>
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { CompTemplateType } from '../constants.js'
import PlaceholderItem from 'public/src/pages/components/ccc/base/PlaceholderItem.vue'
import VirtualSwiper from 'public/src/pages/components/ccc/base/swiper/VirtualSwiper.vue'
import MultiLineHeader from '../components/headers/index.vue'
import SuperDeals from '../components/SuperDeals.vue'
import Trend from '../components/Trend.vue'
import Shop from '../components/Shop.vue'
import OtherTemplate from '../components/OtherTemplate.vue'
import { register } from 'swiper/element'

if(typeof window !== 'undefined'){
  register()
}
export default {
  name: 'SingleContainer',
  components: {
    PlaceholderItem,
    VirtualSwiper,
    MultiLineHeader,
    SuperDeals,
    Trend,
    Shop,
    OtherTemplate,
  },
  props: {
    ...commonProps,
    // 商品项配置
    productItemConfig: {
      type: Object,
      default: () => {}
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    isFirstPage: {
      type: Boolean,
      default: true
    },
    // 容器索引
    containerIndex: {
      type: Number,
      default: 0,
    },
    // 置顶商品abt
    homeAdpNum: {
      type: String,
      default: ''
    },
    // 不贵是否加强价格
    showStrengthenPrice: {
      type: Boolean,
      default: false
    },
    // 是否使用频道所对应的cate_id
    useFrontCateId: {
      type: Boolean,
      default: false
    },
    cropRate: {
      type: String,
      default: '3-4'
    },
    // 不贵落地页早期tmg请求标志
    isEarlyRequest0903: {
      type: Boolean,
      default: false
    },
    // abt控制是否展示折扣标签，合规价
    homeDiscountNew: {
      type: String,
      default: ''
    },
    // 合规类型
    suggestedSaleType: {
      type: String,
      default: ''
    },
  },
  data() {
    const viewMoreText = this.language?.productList?.SHEIN_KEY_PWA_21825
    const { props } = this.propData || {}
    const { metaData = {} } = props || {}
    const { 
      bgFillType,
      bgImage,
      bgColor,
      templateType,
      nextLine = 0,
      cmcOneHalfTittleImage,
      productsNum = 4.2,
    } = metaData
    return {
      bgFillType,
      bgImage,
      bgColor,
      templateType,
      viewMoreText,
      productsNum: Number(productsNum),
      options: {
        direction: 'horizontal',
        slidesPerView: Number(productsNum),
        resistance: true, // 抵抗率, 能左右回弹
        virtualEnable: true, // 启用虚拟slides
        observeResize: true, // 监听resize，自动更新
        noPadding: true, // 商品占位图不需要左边距
      },
      nextLine: Number(nextLine),
      cmcOneHalfTittleImage,
    }
  },
  computed: {
    currentComponent() {
      if ([CompTemplateType.brand, CompTemplateType.category, CompTemplateType.custom, CompTemplateType.newProduct].includes(this.templateType)) {
        return 'OtherTemplate'
      }
      // 首字母大写
      const componentName = this.templateType?.charAt(0)?.toUpperCase() + this.templateType?.slice(1)

      return componentName
    },
    productsList() {
      let { products = [], flashProducts = [], templateType = '' } = this.propData?.props?.metaData ?? {}
      if (templateType === 'superDeals') {
        products = [...flashProducts, ...products]
      }
      products = products?.slice(0, 10) // 取前10条数据，防止多余的数据
      return products
    },
    // 1/2 容器标题类型数据重置。不在中间层处理，防止1/4拉伸后标题数据不一致
    headerPropData() {
      let propData  = this.propData || {}
      if (this.cmcOneHalfTittleImage?.src) { // 优先取cmc标题图
        propData.props.metaData.titleImage = this.cmcOneHalfTittleImage
        propData.props.metaData.titleType = 'image'
      }
      // 如果CCC或者CMC都没有配置标题图，展示文字
      if(!propData?.props?.metaData?.titleImage?.src){
        propData.props.metaData.titleImage = {}
        propData.props.metaData.titleType = 'text'
      }
      return propData
    },
  },
}
</script>

<style lang="less">
.multi-line__single_content {
  width: 100%;
  padding-top: .2133rem;
  padding-left: .1067rem;// 4px
  background: #fff;
  .single-container__swiper {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    max-width: 100vw;
    &_slide {
      flex-shrink: 0;
      width: calc(100% / 4.2); // 默认4.2个商品
      border-right: 4px solid transparent;
    }
  }
  /* 保证商品项占位的宽度与商品一致 */
  .product-item__horizontal {
    padding-left: 0;
    .product-item__horizontal-item {
      margin: 0;
      border: none;
      flex-shrink: 0;
      border-right: 4px solid transparent;
    }
  }
}
</style>
