<template>
  <!-- 商品区域 -->
  <ProductItem
    ref="goodsItem"
    v-tap="getTapAnalysis(productItem, productItemIndex)"
    v-expose="getExposeAnalysis(productItem, productItemIndex)"
    :item="productItem"
    :index="productItemIndex"
    :config="productItemConfig"
    :language="itemLanguage"
    :constant-data="constantData"
    :column="productsNum"
    :da-rid="false"
    :context="context"
    :show-view-more="showViewMore"
    :view-more-text="viewMoreText"
    :css-right="cssRight"
    :lcp="isFirstPage && productItemIndex < 5"
    :comp-src="'multi-line-super-deals'"
    :comp-img-design-width="192"
    :suggested-sale-type="suggestedSaleType"
    class="multi-line__superDeals_item"
    :class="{ 'strengthen-price': isShowStrengthenPrice, 'product-simple__suggested_label': isShowLowestPriceLabelLang, 'half-quarter__superDeals': componentSize != '1' && productItem.type === 'superSale' }"
    :crop-rate="cropRate"
    :price-font-size="priceFontSize"
    @clickItem="toSuperSalePage(productItem)"
    @viewMore="toSuperSalePage(productItem)"
  />
</template>
<script>
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin'
import useProductItemMixin from 'public/src/pages/components/ccc/components/mixins/use-product-item'
import ProductItem from 'public/src/pages/components/ccc/base/ProductItemSimpleCccBff'
import PlaceholderItem from '../../../base/PlaceholderItem'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { CompSize } from '../constants.js'
export default {
  name: 'MultiLineSupeDeals',
  components: {
    ProductItem,
    PlaceholderItem,
    BaseImg
  },
  mixins: [mixins, useProductItemMixin],
  props: {
    ...commonProps,
    isFirstPage: {
      type: Boolean,
      default: true,
    },
    // 商品项配置
    productItemConfig: {
      type: Object,
      default: () => {}
    },
    productsList: {
      type: Array,
      default: () => []
    },
    productItem: {
      type: Object,
      default: () => ({})
    },
    // 商品项索引
    productItemIndex: {
      type: Number,
      default: 0
    },
    // 容器索引
    containerIndex: {
      type: Number,
      default: 0
    },
    showViewMore: {
      type: Boolean,
      default: false
    },
    viewMoreText: {
      type: String,
      default: ''
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    // 置顶商品abt
    homeAdpNum: {
      type: String,
      default: ''
    },
    // 不贵是否加强价格
    showStrengthenPrice: {
      type: Boolean,
      default: false
    },
    // 是否使用频道所对应的cate_id
    useFrontCateId: {
      type: Boolean,
      default: false
    },
    cropRate: {
      type: String,
      default: '3-4'
    },
    // 不贵落地页早期tmg请求标志
    isEarlyRequest0903: {
      type: Boolean,
      default: false
    },
    // 商品数量
    productsNum: {
      type: Number,
      default: 0
    },
    // abt控制是否展示折扣标签，合规价
    homeDiscountNew: {
      type: String,
      default: ''
    },
    // 合规类型
    suggestedSaleType: {
      type: String,
      default: ''
    },
  },
  data() {
    const { metaData: { componentSize, templateType, priceFontSize = {}, mallCodes } = {}, markMap = {} } = this.propData?.props || {}
    return {
      componentSize: Number(componentSize),
      templateType,
      markMap,
      priceFontSize,
      mallCodes
    }
  },
  computed: {
    cate() {
      let categoryIds = ''
      if (this.useFrontCateId) {
        // 前台类目 id
        categoryIds = this.context?.curChannelInfo?.tspNodeIds || ''
      } else {
        categoryIds = this.context?.curChannelInfo?.realCateIds || ''
      }
      return {
        markMap: this.markMap,
        hrefType: 'thriftyFind',
        categoryIds,
        hrefTarget: this.context?.curChannelInfo?.tspNodeIds || '',
        mallCodes: this.productItem?.mall_code || '',
        compIndex: this.index,
        item_loc: `${this.containerIndex + 1}_${this.productItemIndex + 1}`,
        component_area: this.componentSize, // 组件面积
        template: this.templateType, // 组件模板
        content_type: {
          homeBelttype: this.productItem?.homeBelt?.type || this.productItem?.type || 'superSale',
          homeBelttext: this.productItem?.homeBelt?.text || '',
          homeBadgetype: 'discount',
          homeBadgetext: this.productItem?.homeBadge?.text || '',
        },
      }
    },
    adpProductsNum() {
      // 不带入展示商品,只带入点击商品
      if (this.homeAdpNum === 'no') {
        return 0
      } else if (parseInt(this.homeAdpNum)){
        return parseInt(this.homeAdpNum)
      } else {
        switch (this.componentSize) {
          case CompSize.full:
            return 10
          case CompSize.half:
            return 2
          default:
            return 1
        }
      }
    },
    isShowStrengthenPrice() {
      if (this.componentSize != 1) {
        return false
      }
      // 展示合规价时不展示加强价格
      return this.showStrengthenPrice && this.homeDiscountNew !== 'noshow' && this.homeDiscountNew !== 'compliance'
    },
    isShowLowestPriceLabelLang() {
      if (this.componentSize != 1 || this.homeDiscountNew !== 'compliance' || !this.productItem?.suggestedSalePriceInfo?.description) return false
      return true
    },
  },
  methods: {
    // 跳转
    toSuperSalePage(product) {
      const { goods_id = '', type } = product
      const params = {
        item: {
          ...this.metaData,
          hrefType: this.cate?.hrefType,
          hrefTarget: this.cate?.hrefTarget,
          item_loc: `${this.index}_${this.containerIndex}`,
          adp: ''
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: this.containerIndex,
        isBff: true,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      // 列表需要的参数
      const listView =
        window?.localStorage.getItem('super_deals_product_list_view') || ''
      const productsList = this.productsList.slice(0, this.adpProductsNum)
      let flashIds, superIds
      if (type == 'flashSale') {
        // 组装数据
        flashIds = [
          goods_id,
          ...productsList
            .filter(i => i.type == 'flashSale')
            ?.map(i => i.goods_id)
            .filter(i => i !== goods_id)
        ].join(',')
        superIds = productsList
          .filter(i => i.type != 'flashSale')
          ?.map(i => i.goods_id)
          .join(',')
      } else {
        // 组装数据
        superIds = [
          goods_id,
          ...productsList
            .filter(i => i.type != 'flashSale')
            ?.map(i => i.goods_id)
            .filter(i => i !== goods_id)
        ].join(',')
        flashIds = productsList
          .filter(i => i.type == 'flashSale')
          ?.map(i => i.goods_id)
          .join(',')
      }
      let nodeId = '', realCateIds = ''
      switch (Number(this.metaData.categoryType)) {
        case 1:
          // 选中“默认”或“跟随频道”时，从频道获取前台类目；
          nodeId = this.context?.curChannelInfo?.tspNodeIds
          realCateIds = this.context?.curChannelInfo?.realCateIds || ''
          break
        case 3:
          // 选中“前台类目”时，优先使用组件配置的前台类目。前台类目为空时，从频道获取前台类目
          nodeId = this.metaData.frontCateIds || this.context?.curChannelInfo?.tspNodeIds || ''
          realCateIds = this.metaData.categoryIds || this.context?.curChannelInfo?.realCateIds || ''
          break
        // 选中“全品类”时，前台类目传空，获取全部商品
      }
      const adpStr = `adp=${superIds}&flash_adp=${flashIds}&cat_id=${realCateIds}&node_id=${nodeId}&pagefrom=page_home&list_view=${listView}`
      url = url.indexOf('?') > -1 ? `${url}&${adpStr}` : `${url}?${adpStr}`
      url = `${url}&eventTimestamp=${new Date().getTime()}`
      if (this.isEarlyRequest0903) {
        url = `${url}&isEarlyRequest0903=1`
      }
      if (this.mallCodes) {
        url = `${url}&mall_code=${this.mallCodes}`
      }
      this.jumpLink({
        url,
        hrefType: 'super-deals'
      })
    },
    getTapAnalysis(item, i) {
      return this.getProductAnalysis('2-22-4', {
        index: i,
        item,
        cate: this.cate,
        isJumpToList: true,
        useBffApi: true,
        setCode: `${this.containerIndex + 1}_${this.componentSize}_${this.templateType}`
      })
    },
    getExposeAnalysis(item, i) {
      return this.getProductAnalysis('2-22-3', {
        type: 'expose',
        index: i,
        item,
        cate: this.cate,
        isJumpToList: true,
        useBffApi: true,
        setCode: `${this.containerIndex + 1}_${this.componentSize}_${this.templateType}`
      })
    },
  }
}
</script>
<style lang="less" scoped>
.half-quarter__superDeals /deep/ .product-card__top-wrapper .top-wrapper__bottom {
  bottom: -0.5px
}
.strengthen-price.simple-row-item {
  /deep/ .product-card__bottom-wrapper {
    padding-top: 2px;
  }
  /deep/ .bff-price-container__top {
    padding: 0 4px 0 8px;
    height: 100%;
    text-align: center;
  }
  /deep/ .bff-price-container {
    margin: 0 auto;
    min-width: 1.52rem;
    width: fit-content;
    max-width: calc(100% - 6px); // 避免100%,截断下降箭头
    min-height: unset;
    height: 18px;
    border-radius: 9px;
    background: linear-gradient(93.84deg, #FF3F3F 7.44%, #FF7144 97.19%);
    justify-content: center;
    position: relative;
    overflow: unset;
    padding: 0;
    .product-card__camel-case-price__placeholder {
      display: none !important; // 隐藏占位符，保持加强价格垂直居中
    }
    .prices-info__sale-price {
      width: 100%;
      color: #fff;
    }
    &::after {
      position: absolute;
      left: -3px;
      top: 45%;
      content: '';
      display: block;
      width: 12px;
      height: 16px;
      background-image: url('https://shein.ltwebstatic.com/svgicons/2024/07/18/17212901792622301071.svg');
      transform: translateY(-50%) scaleX(1) /* rtl: translateY(-50%) scaleX(-1) */;
    }
  }
}
</style>
