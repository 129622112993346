<template>
  <!-- 商品区域 -->
  <ProductItem
    ref="goodsItem"
    v-tap="getTapAnalysis(productItem, productItemIndex)"
    v-expose="getExposeAnalysis(productItem, productItemIndex)"
    :item="productItem"
    :index="productItemIndex"
    :config="productItemConfig"
    :language="itemLanguage"
    :constant-data="constantData"
    :column="productsNum"
    :da-rid="false"
    :context="context"
    :show-view-more="showViewMore"
    :view-more-text="viewMoreText"
    :css-right="cssRight"
    :crop-rate="cropRate"
    :comp-src="'multi-line-trend'"
    :comp-img-design-width="192"
    :suggested-sale-type="suggestedSaleType"
    :price-font-size="priceFontSize"
    :lcp="isFirstPage && productItemIndex < 5"
    :class="{ 'half-trend': componentSize == '0.5', 'product-simple__suggested_label': isShowLowestPriceLabelLang }"
    @clickItem="toTrendsChannelUrl(productItem)"
    @viewMore="toTrendsChannelUrl(productItem)"
  />
</template>
<script>
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin'
import useProductItemMixin from 'public/src/pages/components/ccc/components/mixins/use-product-item'
import ProductItem from 'public/src/pages/components/ccc/base/ProductItemSimpleCccBff'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { CompSize } from '../constants.js'
import { mapGetters } from 'vuex'
export default {
  name: 'MultiLineTrend',
  components: {
    ProductItem,
  },
  mixins: [mixins, useProductItemMixin],
  props: {
    ...commonProps,
    isFirstPage: {
      type: Boolean,
      default: true,
    },
    // 商品项配置
    productItemConfig: {
      type: Object,
      default: () => {}
    },
    productsList: {
      type: Array,
      default: () => []
    },
    productItem: {
      type: Object,
      default: () => ({})
    },
    // 商品项索引
    productItemIndex: {
      type: Number,
      default: 0
    },
    // 容器索引
    containerIndex: {
      type: Number,
      default: 0
    },
    showViewMore: {
      type: Boolean,
      default: false
    },
    viewMoreText: {
      type: String,
      default: ''
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    // 置顶商品abt
    homeAdpNum: {
      type: String,
      default: ''
    },
    cropRate: {
      type: String,
      default: '3-4'
    },
    // 商品数量
    productsNum: {
      type: Number,
      default: 0
    },
    // abt控制是否展示折扣标签，合规价
    homeDiscountNew: {
      type: String,
      default: ''
    },
    // 合规类型
    suggestedSaleType: {
      type: String,
      default: ''
    },
  },
  data() {
    const { metaData: { componentSize, templateType, priceFontSize = {} } = {}, markMap = {} } = this.propData?.props || {}
    return {
      componentSize: Number(componentSize),
      templateType,
      markMap,
      priceFontSize
    }
  },
  computed: {
    ...mapGetters('config_index', ['channelId', 'topTabList']),
    tabIndex() {
      return this.topTabList?.findIndex(tab => tab.channelId === this.channelId) ?? ''
    },
    cate() {
      return (item, index) => {
        const info_flow = [this.index + 1, item.homeTrends?.contentCarrierId || '-', '-', `${index + 1}_${item.goods_id}`, item?.homeTrends?.productSelectUrlId || '-', item?.rec_mark || '-'].join('`')
        return {
          markMap: this.markMap,
          hrefType: 'trendsChannel',
          compIndex: this.index,
          mallCodes: this.productItem?.mall_code || '',
          hrefTarget: this.context?.curChannelInfo?.realCateIds || '',
          item_loc: `${this.containerIndex + 1}_${this.productItemIndex + 1}`,
          component_area: this.componentSize, // 组件面积
          template: this.templateType, // 组件模板
          content_type: {
            homeBelttype: 'trend',
            homeBelttext: this.productItem?.homeBelt?.text || '',
            homeBadgetype: 'trends',
            homeBadgetext: this.productItem?.homeBadge?.text || '',
          },
          extData: { info_flow },
        }
      }
    },
    adpProductsNum() {
      // 不带入展示商品,只带入点击商品
      if (this.homeAdpNum === 'no') {
        return 0
      } else if (parseInt(this.homeAdpNum)){
        return parseInt(this.homeAdpNum)
      } else {
        switch (this.componentSize) {
          case CompSize.full:
            return 10
          case CompSize.half:
            return 2
          default:
            return 1
        }
      }
    },
    isShowLowestPriceLabelLang() {
      if (this.componentSize != 1 || this.homeDiscountNew !== 'compliance' || !this.productItem?.suggestedSalePriceInfo?.description) return false
      return true
    },
  },
  methods: {
    getTapAnalysis(item, index) {
      return this.getProductAnalysis('2-22-4', {
        index,
        item,
        cate: this.cate(item, index),
        useBffApi: true,
        setCode: `${this.containerIndex + 1}_${this.componentSize}_${this.templateType}`
      })
    },
    getExposeAnalysis(item, index) {
      return this.getProductAnalysis('2-22-3', {
        type: 'expose',
        index: index + 4,
        item,
        cate: this.cate(item, index),
        isJumpToList: true,
        useBffApi: true,
        setCode: `${this.containerIndex + 1}_${this.componentSize}_${this.templateType}`
      })
    },
    // 跳转到趋势频道
    toTrendsChannelUrl(product) {
      let query = {
        page_from: ['page_home', this.tabIndex !== '' ? this.tabIndex + 1 : '-', this.context?.curChannelInfo?.tabName || '-', 'FLEXIBLE_LAYOUT_FOR_SALEZONE' ].join('`')
      }
      // 增加真实分类id
      this.context?.curChannelInfo?.realCateIds && (query.catId = this.context?.curChannelInfo?.realCateIds || '')
      // 点击商品
      const currentGoods = product?.goods_id || ''
      const productsList = this.productsList.slice(0, this.adpProductsNum)
      let excludeGoods = []
      let arrList = productsList?.filter(item => item?.goods_id !== currentGoods)?.map(item => `${item?.homeTrends?.contentCarrierId}_${item?.goods_id}`)
      if(product?.homeTrends?.contentCarrierId && product?.goods_id) {
        excludeGoods.push(`${product?.homeTrends?.contentCarrierId}_${product?.goods_id}`)  
      }
      excludeGoods = excludeGoods.concat(arrList)
      if(excludeGoods?.length) {
        query.contentCarrierId_adp = excludeGoods.join(',')
      }
      const params = {
        item: {
          ...this.metaData,
          hrefType: this.cate(product)?.hrefType,
          hrefTarget: this.context?.curChannelInfo?.realCateIds || '',
          item_loc: `${this.index}_${this.containerIndex}`,
          adp: '',
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: this.containerIndex,
        isBff: true,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      let queryString = Object.keys(query).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`).join('&')
      url = url.indexOf('?') > -1 ? `${url}&${queryString}` : `${url}?${queryString}`
      if (!url || url.indexOf('javascript:;') > -1) return
      this.jumpLink({ url, hrefType: this.cate(product)?.hrefType })
    }
  }
}
</script>
<style lang="less">
.half-trend.simple-row-item .product-card__top-wrapper .top-wrapper__bottom {
  bottom: -0.5px
}
</style>
