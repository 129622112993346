var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ProductItem',{directives:[{name:"tap",rawName:"v-tap",value:(_vm.getTapAnalysis(_vm.shopProduct, _vm.productItemIndex)),expression:"getTapAnalysis(shopProduct, productItemIndex)"},{name:"expose",rawName:"v-expose",value:(_vm.getExposeAnalysis(_vm.shopProduct, _vm.productItemIndex)),expression:"getExposeAnalysis(shopProduct, productItemIndex)"}],ref:"goodsItem",staticClass:"multi-line-shop-item",class:{ 'product-simple__suggested_label': _vm.isShowLowestPriceLabelLang },attrs:{"item":_vm.shopProduct,"index":_vm.productItemIndex,"config":_vm.productItemConfig,"language":_vm.itemLanguage,"constant-data":_vm.constantData,"column":_vm.productsNum,"da-rid":false,"context":_vm.context,"css-right":_vm.cssRight,"crop-rate":_vm.cropRate,"lcp":_vm.isFirstPage && _vm.productItemIndex < 5,"comp-src":'multi-line-shop',"comp-img-design-width":192,"price-font-size":_vm.priceFontSize,"suggested-sale-type":_vm.suggestedSaleType},on:{"clickItem":function($event){return _vm.clickItem()}},scopedSlots:_vm._u([{key:"imgBottom",fn:function(){return [_c('div',{staticClass:"multi-line-shop-item__bottom",style:(_vm.beltBgStyle(_vm.shopProduct.homeBelt))},[(
          Number(_vm.productItem.storeAttentionStatus) == 1||
            _vm.productItem.storeWishCount ||
            Number(_vm.productItem.storeRating) >= 4
        )?_c('div',{staticStyle:{"height":"14px","width":"100%","padding-top":"2px"}},[_c('swiper-container',{ref:"productTagSwiper",staticClass:"swiper-container",attrs:{"destroy-on-disconnected":"false","init":"false","slides-per-view":"1"}},[(Number(_vm.productItem.storeAttentionStatus) == 1)?_c('swiper-slide',{staticClass:"swiper-slide"},[_c('span',{staticClass:"follow"},[_vm._v("\n              "+_vm._s(_vm.productItem?.storeAttentionText || 'Following')+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.productItem.storeWishCount)?_c('swiper-slide',{staticClass:"swiper-slide sales"},[_vm._v("\n            "+_vm._s(_vm.productItem.storeWishCount)+"\n          ")]):_vm._e(),_vm._v(" "),(Number(_vm.productItem.storeRating) >= 4)?_c('swiper-slide',{staticClass:"swiper-slide"},[_c('ProductItemStarComment',{attrs:{"num-style":{
                color: 'rgba(255, 255, 255, 0.8)'
              },"star-style":{
                transform: _vm.cssRight ? 'scaleX(-1)' : 'scaleX(1)'
              },"cur-data":{
                starComment: {
                  comment_num_str:
                    Number(_vm.productItem.storeRating) >= 5 ? 5 : Number(_vm.productItem.storeRating)
                }
              }}})],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"multi-line-shop-item__storeinfo"},[_c('div',{staticClass:"left"},[_vm._v("\n          "+_vm._s(_vm.productItem.title)+"\n        ")]),_vm._v(" "),_c('Icon',{staticClass:"icon-more-right",attrs:{"name":"sui_icon_more_right_12px","color":"#FFF","size":"10","is-rotate":_vm.cssRight}})],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }